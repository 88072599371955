import React, { createContext, type ReactNode, useMemo } from 'react';

import { type MessageDescriptor, useIntl } from 'react-intl-next';

import { useRecentWorkArtifact } from '@atlassian/recent-work-client/flight-deck';

import { type AllEventTypeMessages } from './types';
import {
	type EventTypeMesasgeOptions,
	LOCAL_EVENT_TYPE_MESSAGES,
	messageForEventType,
	translatedStringsToMessageDescriptors,
} from './utils';

interface MessagesContextType {
	eventTypeMessages: AllEventTypeMessages;
	eventTypeMessagesTranslated?: AllEventTypeMessages;
}

export const RecentWorkEventTypeMessagesContext = createContext<MessagesContextType>({
	eventTypeMessages: LOCAL_EVENT_TYPE_MESSAGES,
});

export interface MessagesProviderProps {
	children: ReactNode;
	locale?: string;
}

export function RecentWorkEventTypeMessagesProvider({ children, locale }: MessagesProviderProps) {
	const artifact = useRecentWorkArtifact(locale);

	const providerValue = useMemo(
		() => ({
			eventTypeMessages: LOCAL_EVENT_TYPE_MESSAGES,
			eventTypeMessagesTranslated: translatedStringsToMessageDescriptors(
				artifact?.localization.eventTypes,
			),
		}),
		[artifact],
	);

	return (
		<RecentWorkEventTypeMessagesContext.Provider value={providerValue}>
			{children}
		</RecentWorkEventTypeMessagesContext.Provider>
	);
}

export function WrappedRecentWorkEventTypeMessagesProvider({ children }: { children: ReactNode }) {
	const { locale } = useIntl();
	return (
		<RecentWorkEventTypeMessagesProvider locale={locale}>
			{children}
		</RecentWorkEventTypeMessagesProvider>
	);
}

export const useEventTypeMessagesContext = () =>
	React.useContext(RecentWorkEventTypeMessagesContext);

export function useEventTypeMessage(
	eventType: string,
	options: EventTypeMesasgeOptions = {},
): MessageDescriptor {
	const { eventTypeMessages, eventTypeMessagesTranslated: translatedMessagesMap } =
		useEventTypeMessagesContext();

	return messageForEventType(eventType, translatedMessagesMap || eventTypeMessages, options);
}
