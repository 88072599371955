import { useMemo } from 'react';

import { useFlightDeckArtifact } from '@atlassiansox/flight-deck-frontend-client';

import { FD_CLIENT_FETCH_PROPS, type FlightDeckConfig } from '../services/hydrator-config-client';

export type {
	FlightDeckConfig,
	TranslatedEventTypeMessages,
} from '../services/hydrator-config-client';

export const useRecentWorkArtifact = (locale?: string) => {
	const dimensions = useMemo(
		() => ({
			...FD_CLIENT_FETCH_PROPS.dimensions,
			locale,
		}),
		[locale],
	);

	return useFlightDeckArtifact<FlightDeckConfig>(FD_CLIENT_FETCH_PROPS.name, dimensions);
};
