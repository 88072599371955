import { type MessageDescriptor } from 'react-intl-next';

import type { TranslatedEventTypeMessages } from '@atlassian/recent-work-client/flight-deck';

import {
	commentedEventTypeMessages,
	createdEventTypeMessages,
	defaultEventTypeMessages,
	editedEventTypeMessages,
	publishedEventTypeMessages,
	updatedEventTypeMessages,
	viewedEventTypeMessages,
} from './messages';
import {
	type AllEventTypeMessages,
	type EventTypeMessages,
	type EventTypeMessageTypes,
} from './types';

export const LOCAL_EVENT_TYPE_MESSAGES: AllEventTypeMessages = {
	commented: commentedEventTypeMessages,
	created: createdEventTypeMessages,
	edited: editedEventTypeMessages,
	published: publishedEventTypeMessages,
	updated: updatedEventTypeMessages,
	viewed: viewedEventTypeMessages,
};

export type EventTypeMesasgeOptions = {
	/** Message to display absolute time. E.g. You commented on Aug 10th, 2022. If false, message to display relative time. E.g. You commented 2 hours ago. Defaulted to true  */
	isAbsolute?: boolean;
	/** Message to display your own activities. E.g. You commented on Aug 10th, 2022. If false, message to not your own activity relative time. E.g. Commented on Aug 10th, 2022. Defaulted to true  */
	isMyActivityData?: boolean;
	/** Message to display a simple message. E.g. You commented. If false, shows the time. E.g. You commented on Aug 10th, 2022. Defaulted to false  */
	isSimple?: boolean;
};

export function messageForEventType(
	eventType: string,
	eventTypeMessages: AllEventTypeMessages = LOCAL_EVENT_TYPE_MESSAGES,
	options: EventTypeMesasgeOptions = {},
): MessageDescriptor {
	const { isAbsolute = true, isMyActivityData = true, isSimple = false } = options;

	const eventTypeMessage = eventTypeMessages.hasOwnProperty(eventType)
		? eventTypeMessages[eventType]
		: defaultEventTypeMessages;

	if (isSimple) {
		if (isMyActivityData) {
			return eventTypeMessage.standalone;
		} else {
			return eventTypeMessage.multiStandalone;
		}
	} else {
		if (isMyActivityData && isAbsolute) {
			return eventTypeMessage.absolute;
		} else if (isMyActivityData && !isAbsolute) {
			return eventTypeMessage.relative;
		} else if (!isMyActivityData && isAbsolute) {
			return eventTypeMessage.multiAbsolute;
		} else {
			return eventTypeMessage.multiRelative;
		}
	}
}

export function translatedStringsToMessageDescriptors(
	translatedStrings: TranslatedEventTypeMessages | undefined,
): AllEventTypeMessages | undefined {
	if (!translatedStrings) {
		return undefined;
	}

	return Object.entries(translatedStrings).reduce<AllEventTypeMessages>(
		(acc, [eventType, translatedMessages]) => {
			acc[eventType] = Object.entries(translatedMessages).reduce<EventTypeMessages>(
				(eventTypeMessages, [translatedKey, translatedEntry]) => {
					eventTypeMessages[translatedKey as EventTypeMessageTypes] = {
						defaultMessage: translatedEntry,
						id: `recentWork.eventTypeMessage.translated.${translatedKey}.no-translate`,
					};

					return eventTypeMessages;
				},
				{} as EventTypeMessages,
			);

			return acc;
		},
		{},
	);
}
