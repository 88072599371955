import { defineMessages } from 'react-intl-next';

import { type EventTypeMessages } from './types';

export const commentedEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You commented on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - comment added',
		id: 'recentWork.eventTypeMessage.absolute.commented',
	},
	relative: {
		defaultMessage: 'You commented {relativeTime}',
		description: 'action taken by the user on this object with relative timestamp - comment added',
		id: 'recentWork.eventTypeMessage.relative.commented',
	},
	standalone: {
		defaultMessage: 'You commented',
		description: 'action taken by the user on this object - comment added',
		id: 'recentWork.eventTypeMessage.commented',
	},
	multiAbsolute: {
		defaultMessage: 'Commented on {absoluteTime}',
		description: 'action taken by the user on this object with absolute timestamp - comment added',
		id: 'recentWork.eventTypeMessage.multi.absolute.commented',
	},
	multiRelative: {
		defaultMessage: 'Commented {relativeTime}',
		description: 'action taken on this object with relative timestamp - comment added',
		id: 'recentWork.eventTypeMessage.multi.relative.commented',
	},
	multiStandalone: {
		defaultMessage: 'Commented',
		description: 'action taken on this object - comment added',
		id: 'recentWork.eventTypeMessage.multi.commented',
	},
});

export const createdEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You created on {absoluteTime}',
		description:
			'action taken by the user on this object with absolute timestamp - initially created',
		id: 'recentWork.eventTypeMessage.absolute.created',
	},
	relative: {
		defaultMessage: 'You created {relativeTime}',
		description:
			'action taken by the user on this object with relative timestamp - initially created',
		id: 'recentWork.eventTypeMessage.relative.created',
	},
	standalone: {
		defaultMessage: 'You created',
		description: 'action taken by the user on this object - initially created',
		id: 'recentWork.eventTypeMessage.created',
	},
	multiAbsolute: {
		defaultMessage: 'Created on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - initially created',
		id: 'recentWork.eventTypeMessage.multi.absolute.created',
	},
	multiRelative: {
		defaultMessage: 'Created {relativeTime}',
		description: 'action taken on this object with relative timestamp - initially created',
		id: 'recentWork.eventTypeMessage.multi.relative.created',
	},
	multiStandalone: {
		defaultMessage: 'Created',
		description: 'action taken on this object - initially created',
		id: 'recentWork.eventTypeMessage.multi.created',
	},
});

export const editedEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You edited on {absoluteTime}',
		description: 'action taken by the user on this object with absolute timestamp - edited',
		id: 'recentWork.eventTypeMessage.absolute.edited',
	},
	relative: {
		defaultMessage: 'You edited {relativeTime}',
		description: 'action taken by the user on this object with relative timestamp - edited',
		id: 'recentWork.eventTypeMessage.relative.edited',
	},
	standalone: {
		defaultMessage: 'You edited',
		description: 'action taken by the user on this object - edited',
		id: 'recentWork.eventTypeMessage.edited',
	},
	multiAbsolute: {
		defaultMessage: 'Edited on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - edited',
		id: 'recentWork.eventTypeMessage.multi.absolute.edited',
	},
	multiRelative: {
		defaultMessage: 'Edited {relativeTime}',
		description: 'action taken on this object with relative timestamp - edited',
		id: 'recentWork.eventTypeMessage.multi.relative.edited',
	},
	multiStandalone: {
		defaultMessage: 'Edited',
		description: 'action taken on this object - edited',
		id: 'recentWork.eventTypeMessage.multi.edited',
	},
});

export const publishedEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You published on {absoluteTime}',
		description: 'action taken by the user on this object with absolute timestamp - published',
		id: 'recentWork.eventTypeMessage.absolute.published',
	},
	relative: {
		defaultMessage: 'You published {relativeTime}',
		description: 'action taken by the user on this object with relative timestamp - published',
		id: 'recentWork.eventTypeMessage.relative.published',
	},
	standalone: {
		defaultMessage: 'You published',
		description: 'action taken by the user on this object - published',
		id: 'recentWork.eventTypeMessage.published',
	},
	multiAbsolute: {
		defaultMessage: 'Published on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - published',
		id: 'recentWork.eventTypeMessage.multi.absolute.published',
	},
	multiRelative: {
		defaultMessage: 'Published {relativeTime}',
		description: 'action taken on this object with relative timestamp - published',
		id: 'recentWork.eventTypeMessage.multi.relative.published',
	},
	multiStandalone: {
		defaultMessage: 'Published',
		description: 'action taken on this object - published',
		id: 'recentWork.eventTypeMessage.multi.published',
	},
});

export const updatedEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You updated on {absoluteTime}',
		description: 'action taken by the user on this object with absolute timestamp - updated',
		id: 'recentWork.eventTypeMessage.absolute.updated',
	},
	relative: {
		defaultMessage: 'You updated {relativeTime}',
		description: 'action taken by the user on this object with relative timestamp - updated',
		id: 'recentWork.eventTypeMessage.relative.updated',
	},
	standalone: {
		defaultMessage: 'You updated',
		description: 'action taken by the user on this object - updated',
		id: 'recentWork.eventTypeMessage.updated',
	},
	multiAbsolute: {
		defaultMessage: 'Updated on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - updated',
		id: 'recentWork.eventTypeMessage.multi.absolute.updated',
	},
	multiRelative: {
		defaultMessage: 'Updated {relativeTime}',
		description: 'action taken on this object with relative timestamp - updated',
		id: 'recentWork.eventTypeMessage.multi.relative.updated',
	},
	multiStandalone: {
		defaultMessage: 'Updated',
		description: 'action taken on this object - updated',
		id: 'recentWork.eventTypeMessage.multi.updated',
	},
});

export const viewedEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You viewed on {absoluteTime}',
		description: 'action taken by the user on this object with absolute timestamp - viewed',
		id: 'recentWork.eventTypeMessage.absolute.viewed',
	},
	relative: {
		defaultMessage: 'You viewed {relativeTime}',
		description: 'action taken by the user on this object with relative timestamp - viewed',
		id: 'recentWork.eventTypeMessage.relative.viewed',
	},
	standalone: {
		defaultMessage: 'You viewed',
		description: 'action taken by the user on this object - viewed',
		id: 'recentWork.eventTypeMessage.viewed',
	},
	multiAbsolute: {
		defaultMessage: 'Viewed on {absoluteTime}',
		description: 'action taken on this object with absolute timestamp - viewed',
		id: 'recentWork.eventTypeMessage.multi.absolute.viewed',
	},
	multiRelative: {
		defaultMessage: 'Viewed {relativeTime}',
		description: 'action taken on this object with relative timestamp - viewed',
		id: 'recentWork.eventTypeMessage.multi.relative.viewed',
	},
	multiStandalone: {
		defaultMessage: 'Viewed',
		description: 'action taken on this object - viewed',
		id: 'recentWork.eventTypeMessage.multi.viewed',
	},
});

export const defaultEventTypeMessages = defineMessages<keyof EventTypeMessages>({
	absolute: {
		defaultMessage: 'You worked on {absoluteTime}',
		description:
			'action taken by the user on this object with an absolute or relative date/time - worked on',
		id: 'recentWork.eventTypeMessage.absolute.workedOn',
	},
	relative: {
		defaultMessage: 'You worked on {relativeTime}',
		description:
			'action taken by the user on this object with an absolute or relative date/time - worked on',
		id: 'recentWork.eventTypeMessage.absolute.workedOn',
	},
	standalone: {
		defaultMessage: 'You worked on',
		description: 'action taken by the user on this object - worked on',
		id: 'recentWork.eventTypeMessage.workedOn',
	},

	multiAbsolute: {
		defaultMessage: 'Worked on {absoluteTime}',
		description: 'action taken on this object with an absolute or relative date/time - worked on',
		id: 'recentWork.eventTypeMessage.multi.absolute.workedOn',
	},
	multiRelative: {
		defaultMessage: 'Worked on {relativeTime}',
		description: 'action taken on this object with an absolute or relative date/time - worked on',
		id: 'recentWork.eventTypeMessage.multi.dateTime.workedOn',
	},
	multiStandalone: {
		defaultMessage: 'Worked on',
		description: 'action taken on this object - worked on',
		id: 'recentWork.eventTypeMessage.multi.workedOn',
	},
});
